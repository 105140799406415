import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

class TagRoute extends React.Component {
    render() {
        const posts = this.props.data.allMarkdownRemark.edges;

        const postLinks = posts.map((singlePost, idx) => {
            const post = singlePost.node;
            return (
                <div
                    className="column card m-3 p-3 is-4 is-narrow"
                    key={`blog-${idx}`}
                >
                    <div className="card-image">
                        <figure className="image">
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                    width: post.frontmatter.featuredimage
                                        .childImageSharp.gatsbyImageData.width,
                                    height: post.frontmatter.featuredimage
                                        .childImageSharp.gatsbyImageData.height,
                                }}
                            />
                        </figure>
                    </div>
                    <div className="card-content">
                        <div className="media">
                            <div className="media-content">
                                <h2>
                                    <Link
                                        className="sitteroo-blog-list-heading"
                                        to={post.fields.slug}
                                    >
                                        {post.frontmatter.title}
                                    </Link>
                                </h2>
                            </div>
                        </div>

                        <div className="content">
                            {post.excerpt}
                            <br />
                        </div>
                    </div>
                    <footer className="card-footer">
                        <div className="card-footer-item is-justify-content-flex-end is-justify-content-center-mobile ">
                            <Link
                                className="button is-rounded is-large is-responsive bg-salmon is-primary"
                                to={post.fields.slug}
                            >
                                read more
                            </Link>
                        </div>
                    </footer>
                </div>
            );
        });
        const { tag } = this.props.pageContext;
        const { totalCount } = this.props.data.allMarkdownRemark;
        const tagHeader = `${totalCount} post${
            totalCount === 1 ? '' : 's'
        } tagged with “${tag}”`;

        return (
            <Layout>
                <Helmet titleTemplate="%s">
                    <title>{`Blog posts tagged with ${tag}`}</title>
                    <meta
                        name="description"
                        content={`${totalCount} blog posts tagged with ${tag}`}
                    />
                    <meta
                        name="og:description"
                        content={`${totalCount} blog posts tagged with ${tag}`}
                    />
                </Helmet>

                <div className="section--green section--with-mask mask-four">
                    <div className="container">
                        <section className="section pt-6">
                            <div className="columns is-multiline">
                                <div className="column">
                                    <ul className="taglist is-primary sitteroo-tags">
                                        <li>
                                            <Link to="/blog/" className="white">
                                                Browse all tags
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <h1 className="sitteroo-h2 has-text-centered pb-6 pt-6">
                            {tagHeader}
                        </h1>

                        <section className="section">
                            <div className="container">
                                <div className="content">
                                    <div className="columns is-multiline is-justify-content-space-between sitteroo">
                                        {postLinks}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="container is-centered p-6" />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default TagRoute;

export const tagPageQuery = graphql`
    query TagPage($tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    excerpt(pruneLength: 100)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        featuredpost
                        featuredimage {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 447
                                    quality: 100
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
